//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { formTaskFormData } from '@/api/modular/flowable/formManage'
import { handleTaskSave, handleTaskTaskData, handleTaskSuspend } from '@/api/modular/flowable/handleTaskManage'
import { buttonTrace } from '@/api/modular/flowable/buttonManage'
// 導入全部自定義表單
import allCustomForm from '@/views/main/customForm/index.js'
import Print from 'print-js'
// 導入按鈕功能的界面
import submit from '../handleTask/submit'
import back from '../handleTask/back'
import turn from '../handleTask/turn'
import entrust from '../handleTask/entrust'
import end from '../handleTask/end'
// import trackingForm from '../todoTask/trackingForm'
import tracking from '../../tracking/tracking'
import jump from '../handleTask/jump'
import addSign from '../handleTask/addSign'
import deleteSign from '../handleTask/deleteSign'

export default {
  components: {
    submit,
    back,
    turn,
    entrust,
    end,
    tracking,
    jump,
    addSign,
    deleteSign
  },
  data() {
    return {
      allCustomFormComps: allCustomForm,
      jsonData: {},
      visible: false,
      confirmLoading: false,
      spinningLoading: false,
      cardLoading: false,
      processDefinitionId: '',
      butLoading: false,
      buttonList: {},
      recordData: [],
      // 是否為自行開發表單
      isCustomForm: false,
      formCompKey: ''
    }
  },
  methods: {
    /**
     * 初始化方法
     */
    open(record) {
      this.recordData = record
      this.formTaskFormDataTaskData(record)
      this.buttonTrace(record)
    },
    /*
     * 判斷字符串是否為 JSON
     * return Boolean
     */
    isJSON(str) {
      if (typeof str === 'string') {
        try {
          const obj = JSON.parse(str)
          if (typeof obj === 'object' && obj) {
            return true
          } else {
            return false
          }
        } catch (e) {
          // console.log('error：' + str + '!!!' + e)
          return false
        }
      }
      console.log('It is not a string!')
    },
    /**
     * 當前任務的任務表單及數據
     */
    formTaskFormDataTaskData(record) {
      this.cardLoading = true
      const values = {}
      values.processDefinitionId = record.procIns.procDef.id
      values.actId = record.activityId
      formTaskFormData(values)
        .then(res => {
          this.cardLoading = false
          if (!res.success) {
            this.$message.error(res.message)
            this.returnPage()
            return
          }

          this.isCustomForm = !this.isJSON(res.data)

          if (this.isCustomForm) {
            this.jsonData = {}
            this.formCompKey = res.data
            this.getTaskData(record)
          } else {
            this.jsonData = JSON.parse(res.data)
            this.getTaskData(record)
          }

          // eslint-disable-next-line handle-callback-err
        })
        .catch(() => {
          this.jsonData = {}
        })
    },
    /**
     * 獲取表單數據接口
     */
    getTaskData(params) {
      this.spinningLoading = true

      handleTaskTaskData({ taskId: params.id }).then(res => {
        this.spinningLoading = false
        if (!res.success) {
          this.$message.error(res.message)
          return
        }

        if (this.isCustomForm) {
          this.$refs.customFormRef.init(res.data.formData)
        } else {
          this.$refs.kfb.setData(JSON.parse(res.data.formData))
        }

        // if (res.success) {
        //   this.$refs.kfb.setData(JSON.parse(res.data.formData))
        // } else {
        //   this.$message.error(res.message)
        // }
      })
    },
    /**
     * 獲取當前任務的按鈕
     */
    buttonTrace(record) {
      buttonTrace({ actId: record.activityId, processDefinitionId: record.procIns.procDef.id }).then((res) => {
        this.buttonList = res.data
      })
    },
    /**
     * 返回並清空已生成的表單
     */
    returnPage() {
      this.$emit('close')
      this.jsonData = {}
    },
    // TODO: 自定義列表處理
    handleOpenSubmit() {
      if (this.isCustomForm) {
        // 自定義表單 do something
        this.$refs.customFormRef
          .getDataForSubmit()
          .then(values => {
            const formData = {}
            formData.formData = JSON.stringify(values)
            this.$refs.submit.open(this.recordData, this.buttonList, formData)
          })
          .catch(() => {})
      } else {
        this.$refs.kfb
          .getData()
          .then(values => {
            const formData = {}
            formData.formData = JSON.stringify(values)
            this.$refs.submit.open(this.recordData, this.buttonList, formData)
          })
          .catch(() => {})
      }
    },

    /**
     * 保存
     */
    handleSave() {
      if (this.isCustomForm) {
        this.$refs.customFormRef
          .getDataForDraft()
          .then(values => {
            this.postHandleSave(values)
          })
          .catch(() => {
            this.$message.error('獲取表單數據失敗')
          })
      } else {
        this.$refs.kfb
          .getData()
          .then(values => {
            this.postHandleSave(values)
          })
          .catch(() => {
            this.$message.error('獲取表單數據失敗')
          })
      }
    },
    /*
     * 保存操作接口
     */
    postHandleSave(values) {
      values.processDefinitionId = this.recordData.processDefinitionId
      const formData = {}
      formData.formData = JSON.stringify(values)
      const req = {}
      req.taskId = this.recordData.id
      req.variables = formData

      this.spinningLoading = true
      handleTaskSave(req)
        .then(res => {
          this.spinningLoading = false
          if (res.success) {
            this.$message.success('保存成功')
            this.returnPage()
          } else {
            this.$message.error('保存失敗：' + res.message)
          }
        })
        .catch(() => {
          this.spinningLoading = false
        })
    },
    handleOpenBack() {
      this.$refs.back.open(this.recordData)
    },
    handleOpenTurn() {
      this.$refs.turn.open(this.recordData)
    },
    handleOpenEntrust() {
      this.$refs.entrust.open(this.recordData)
    },
    handleOpenEnd() {
      this.$refs.end.open(this.recordData)
    },
    handleSuspend() {
      this.spinningLoading = true
      handleTaskSuspend({ taskId: this.recordData.id }).then(res => {
        this.spinningLoading = false
        if (res.success) {
          this.$message.success('掛起成功')
          this.returnPage()
        } else {
          this.$message.error('掛起失敗：' + res.message)
        }
      })
    },
    handleOpenJump() {
      this.$refs.jump.open(this.recordData)
    },
    handleOpenAddSign() {
      this.$refs.addSign.open(this.recordData)
    },
    handleOpenDeleteSign() {
      this.$refs.deleteSign.open(this.recordData)
    },
    handlePrintRow(index, row) {
      Print({ printable: 'printDiv', type: 'html', targetStyles: ['*'] })
    }
  }
}
